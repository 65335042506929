import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { claimActions, ClaimFacade } from '@domgen/data-access-claims';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceSignpostGuard implements CanActivate {
  constructor(
    private _claimFacade: ClaimFacade,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate(): Observable<boolean> {
    return this.checkClaim().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkClaim(): Observable<boolean> {
    return this._claimFacade.activeClaim$.pipe(
      switchMap(() => {
        if (this.optActive()) {
          this._claimFacade.dispatch(claimActions.SeenMaintenanceSignpost());
          return throwError(false);
        }

        return of(true);
      })
    );
  }

  optActive() {
    const el = this.document?.getElementById('optly');
    return el ? Array.from(el?.classList)?.includes('DGX-5340') : false;
  }
}
