import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Api, CalendarDay, CalendarSlot } from '../../../interfaces';
import { SlotType } from '../booking-calendar-day/booking-calendar-day.component';

@Component({
  selector: 'dg-booking-calendar-day-all-day-slot',
  templateUrl: './booking-calendar-day-all-day-slot.component.html',
  styleUrls: ['./booking-calendar-day-all-day-slot.component.scss'],
})
export class BookingCalendarDayAllDaySlotComponent implements OnChanges {
  @Input() day?: CalendarDay;
  @Input() isRebook = false;
  @Input() bookingError = false;
  @Input() currentAppointment?: Api.CurrentAppointment;
  @Output() selected: EventEmitter<CalendarSlot> = new EventEmitter();

  showConfirmationModal = false;
  slotTypes = SlotType;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookingError) {
      if (this.bookingError) {
        this.showConfirmationModal = false;
      }
    }
  }

  handleBookingConfirmation(confirm: boolean) {
    this.showConfirmationModal = confirm;
    if (confirm) {
      const slot = Array.isArray(this.day?.slots)
        ? this.day?.slots[0]
        : this.day?.slots;
      this.selected.emit(slot);
    }
  }

  clearSelection(): void {
    if (!this.day) return;
    this.day.selected = false;
  }
}
