<div class="mt-12 pb-12 container">
  <div class="grid-row grid-row--no-gutter my-account-banner justify--center">
    <div class="xs-12 md-7 my-account-wrapper">
      <h3 class="main-title">{{ heading }}</h3>
      <h2 class="sub-title">{{ description }}</h2>
      <dgx-components-button
        type="submit"
        variant="primary"
        [ngClass]="{}"
        iconName="arrow-right"
        (click)="routeToMyAccount()"
      >
        {{ buttonLabel }}
      </dgx-components-button>
    </div>
    <div
      class="xs-12 md-5 md--block my-account-image"
      style="background-image: url('./assets/images/my-account.jpg')"
    ></div>
  </div>
</div>
