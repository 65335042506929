import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  Injector,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* MODULES */
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  Config,
  ConfigService,
  ENVIRONMENT,
  GA_CLIENT,
  SharedComponentsUiModule,
} from '@domgen/dgx-components';
/* COMPONENTS */
import {
  ClaimsDataAccessClaimsModule,
  HttpInterceptorService,
} from '@domgen/data-access-claims';
import { BookingMapService } from '@domgen/dgx-components';
import { DgxComponentsLayoutModule } from '@domgen/dgx-fe-components/layout';
import { GAService, GA_CONFIG } from '@domgen/dgx-fe-ga';
import { DgxI18nModule } from '@domgen/dgx-fe-i18n';
import { SharedUiSvgModule } from '@domgen/ui-svg';
import { environment } from '@domgen/util-environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { ClaimsAppComponent } from './claims-app.component';
import { ClaimsFeatureShellRoutingModule } from './claims-feature-shell-routing.module';

export const configFactory = (buildConfigService: ConfigService) => {
  return (): Promise<Config> => {
    return buildConfigService.loadConfigurationData(environment as any);
  };
};
const storeDevToolsModule = !environment.production
  ? [
      StoreDevtoolsModule.instrument({
        name: 'Domestic & General',
        maxAge: 25,
      }),
    ]
  : [];

const gaTagsTokenFactory = (injector: Injector) => {
  return injector.get(AppProvidersConfigToken).gaTagsConfig;
};
@NgModule({
  declarations: [ClaimsAppComponent],
  exports: [ClaimsAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ClaimsFeatureShellRoutingModule,
    SharedComponentsUiModule,
    SharedUiSvgModule,
    HttpClientModule,
    ClaimsDataAccessClaimsModule,
    DgxI18nModule,
    ...storeDevToolsModule,
    DgxComponentsLayoutModule,
  ],
  providers: [GAService],
})
export class ClaimsFeatureShellModule {
  static forRoot(
    appConfig: AppProvidersConfig
  ): ModuleWithProviders<ClaimsFeatureShellModule> {
    return {
      ngModule: ClaimsFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: appConfig },
        { provide: ENVIRONMENT, useValue: environment },
        {
          provide: GA_CONFIG,
          useValue: {
            debug: true,
          },
        },
        {
          provide: APP_INITIALIZER, // ensure the application isn't started before the config file is loaded.
          useFactory: configFactory,
          deps: [ConfigService],
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true,
        },
        {
          provide: GA_CLIENT,
          useFactory: gaTagsTokenFactory,
          deps: [Injector],
        },

        BookingMapService,
        CookieService,
        {
          provide: 'isDevelopmentEnvironment',
          useValue: !environment.production,
        },
      ],
    };
  }
}
