import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Params,
} from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromClaims from '@domgen/data-access-claims';
import { claimActions } from '@domgen/data-access-claims';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError, take } from 'rxjs/operators';
import { LocalStorageService } from '@domgen/dgx-components';
import { State } from '@domgen/data-access-claims';
import { HttpInterceptorService } from '@domgen/data-access-claims';
import { Claim } from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<State>,
    private storage: LocalStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkClaim(state.root.queryParams).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkClaim(params: Params): Observable<boolean> {
    return this.storage.getItem(HttpInterceptorService.BUNDLE_TOKEN).pipe(
      switchMap((athome_aws_bundle) => {
        // if we no bundle token in params or local storage throw error
        if (
          athome_aws_bundle === null &&
          params.athome_aws_bundle === undefined
        ) {
          this.store.dispatch(
            claimActions.Error({ payload: new Error('No bundle token') })
          );
          return of(false);
        }

        const param = params.athome_aws_bundle
          ? params.athome_aws_bundle
          : athome_aws_bundle;

        const newHandoff = params.athome_aws_bundle ? true : false;

        return this.storage.setItem('athome_aws_bundle', param).pipe(
          switchMap(() => {
            // load the current claim
            return this.store.select(fromClaims.getActiveClaim).pipe(
              switchMap((data: Claim | undefined) => {
                if (data === undefined) {
                  this.store.dispatch(
                    claimActions.InitializeCurrentClaim({
                      payload: {
                        param: param,
                        newHandoff: newHandoff,
                      },
                    })
                  );

                  return throwError(true);
                } else {
                  return of(true);
                }
              }),
              take(1)
            );
          })
        );
      })
    );
  }
}
