import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromClaims from '@domgen/data-access-claims';
import { claimActions } from '@domgen/data-access-claims';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError, take } from 'rxjs/operators';

import { State } from '@domgen/data-access-claims';
import { Claim, ClaimStage } from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class ApplianceDetailsGuard implements CanActivate {
  constructor(private store: Store<State>) {}

  canActivate(): Observable<boolean> {
    return this.checkClaim().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkClaim(): Observable<boolean> {
    return this.store.select(fromClaims.getActiveClaim).pipe(
      switchMap((claim: Claim | undefined) => {
        if (
          claim?.claimSelection?.selectionState?.modelNumberAlreadyValidated
        ) {
          this.store.dispatch(
            claimActions.UpdateClaim({
              payload: {
                selectionState: {
                  claimStage: ClaimStage.ApplianceDetailsSubmit,
                },
                route: true,
                request: {
                  Manufacturer: claim.reflect?.manufacturer,
                  ProductType:
                    claim?.claimSelection?.request?.ProductType ||
                    claim.reflect?.productType,
                },
              },
            })
          );
          return throwError(true);
        } else {
          return of(true);
        }
      }),
      take(1)
    );
  }
}
