import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dg-my-account-banner',
  templateUrl: './my-account-banner.component.html',
  styleUrls: ['./my-account-banner.component.scss'],
})
export class MyAccountBannerComponent implements OnInit {
  @Input() heading = `It's easy to track repairs online`;
  @Input()
  description = `Use My Account to track or book repairs, and check or update any of your plans`;
  @Input() buttonLabel = 'Go to My Account';

  constructor() {}

  ngOnInit(): void {}

  routeToMyAccount() {
    window.location.href =
      'https://www.my-account.athome.domesticandgeneral.com/my-account';
  }
}
