<dg-booking-calendar-confirmation
  *ngIf="showConfirmationModal"
  [day]="day"
  [slotType]="slotTypes.ALLDAY"
  [currentAppointment]="currentAppointment"
  [isRebooking]="isRebook"
  (confirmBooking)="handleBookingConfirmation($event)"
></dg-booking-calendar-confirmation>

<p class="typog-body-bold question mb-1">When will the engineer come?</p>
<p class="typog-body">
  After you book, they’ll let you know their arrival time as soon as they can.
</p>

<dgx-components-button
  class="flex"
  (click)="showConfirmationModal = true"
  data-automation="'ConfirmAD'"
  [fill]="true"
  type="submit"
  variant="primary"
>
  Book
</dgx-components-button>

<dgx-components-button
  class="flex mt-3"
  type="button"
  variant="secondary"
  data-automation="'ConfirmAD'"
  (click)="clearSelection()"
  [fill]="true"
>
  See other dates
</dgx-components-button>
