import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CalendarDay, CalendarSlot } from '../../../interfaces';
import { BookingCalendarDateFormats } from '../booking-calendar-constants';
import { Api } from './../../../interfaces/api.interface';

export enum SlotType {
  ALLDAY = 'AllDay',
  SPECIFIC = 'Specific',
}

@Component({
  selector: 'dg-booking-calendar-confirmation',
  templateUrl: './booking-calendar-confirmation.component.html',
  styleUrls: ['./booking-calendar-confirmation.component.scss'],
})
export class BookingCalendarConfirmationComponent implements AfterViewInit {
  @Input() day?: CalendarDay;
  @Input() slotSelected: CalendarSlot | undefined = undefined;
  @Input() slotType?: SlotType;
  @Input() isRebooking = false;
  @Input() currentAppointment?: Api.CurrentAppointment;

  @Output() confirmBooking: EventEmitter<boolean> = new EventEmitter();

  bookingCalendarDateFormats = BookingCalendarDateFormats;

  slotTypes = SlotType;

  get confirmationHeading(): string {
    if (this.isRebooking) return 'Shall we change your booking?';

    if (this.slotType === SlotType.ALLDAY) return 'Shall we book this date?';

    return 'Shall we book this slot?';
  }

  ngAfterViewInit() {
    this.focusButton();
  }

  focusButton() {
    (
      document.querySelector(
        '.dialog dgx-components-button button'
      ) as HTMLButtonElement
    )?.focus();
  }

  decline() {
    this.confirmBooking.emit(false);
  }

  confirm() {
    this.confirmBooking.emit(true);
  }
}
