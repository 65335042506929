import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ErrorHandlerService } from '@domgen/data-access-claims';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError, take } from 'rxjs/operators';
import {
  ConfigService,
  ErrorPageContent,
  ErrorTypes,
} from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class ErrorGuard implements CanActivate {
  constructor(private handler: ErrorHandlerService, private c: ConfigService) {}

  canActivate(): Observable<boolean> {
    return this.checkClaim().pipe(
      switchMap(() => of(true)),
      catchError(() => {
        window.location.href = this.c?.config?.plansHomePage;
        return of(false);
      })
    );
  }

  checkClaim(): Observable<boolean> {
    return this.handler.errorPageContent().pipe(
      take(1),
      switchMap((errorObject: ErrorPageContent) => {
        return errorObject?.errorType === ErrorTypes.Technical ||
          errorObject?.active
          ? of(true)
          : throwError(false);
      })
    );
  }
}
