import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatClientService {
  chatVisible = true;
  private initFailCount = 0;

  constructor(private router: Router) {}

  routeListener() {
    this.initShowHide();

    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap(() => {
        this.showHideChat();
      })
    );
  }

  private initShowHide() {
    // hunt for chat client element every 200ms for 4 tries
    if (this.initFailCount < 4) {
      if (!this.showHideChat()) {
        this.initFailCount++;
        setTimeout(() => {
          // timeout for user experience
          this.initShowHide();
        }, 200);
      }
    }
  }

  private showHideChat(): boolean | void {
    if (this.chatVisible !== this.canShowChat()) {
      const chatContainer = document.getElementById('chat-client-container');
      if (chatContainer) {
        this.chatVisible = !this.chatVisible;
        chatContainer.style.display = this.chatVisible ? 'block' : 'none';
        return true;
      } else {
        return false;
      }
    }
  }

  canShowChat() {
    // route permissions list to show client
    switch (this.router.url) {
      case '/appliance-details':
      case '/repair-details':
      case '/book-engineer':
      case '/book-collection':
      case '/reject':
      case '/error':
        return true;
      default:
        return false;
    }
  }
}
