import { NgModule } from '@angular/core';
import { ClaimsFeatureShellModule } from '@domgen/feature-shell';
import { AppComponent } from './app.component';
import { gaTagsConfig } from './ga-tags-config/ga-tags-config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ClaimsFeatureShellModule.forRoot({
      gaTagsConfig
    })
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
