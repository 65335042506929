import { Component, OnInit } from '@angular/core';
import { ChatClientService } from '@domgen/data-access-claims';
import { BaseComponent, ConfigService } from '@domgen/dgx-components';
import { NavigationLink } from '@domgen/dgx-fe-common';
import { Picture } from '@domgen/dgx-fe-components/media';
import { Observable, map, take, takeUntil } from 'rxjs';
import { ClaimsAppComponentService } from './claims-app-component.service';

@Component({
  selector: 'claims-app-root',
  templateUrl: './claims-app.component.html',
  styleUrls: ['./claims-app.component.scss'],
})
export class ClaimsAppComponent extends BaseComponent implements OnInit {
  appConfig = this.buildConfigService.config;
  loadingState$: Observable<{
    isLoading: boolean;
    message: string | null;
  }> = this.service.loadingState$;

  user$ = this.service.user$;

  headerLinks: NavigationLink[];

  clientLogo: Picture = {
    sourceSet: {},
    alt: 'Domestic & General Logo',
    aspectRatio: '16:9',
    src: 'assets/images/domestic-and-general.png',
  };

  constructor(
    public readonly service: ClaimsAppComponentService,
    readonly buildConfigService: ConfigService,
    private readonly chatClient: ChatClientService
  ) {
    super();
    this.chatClient.routeListener().subscribe();
  }

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.destroy$),
        map((state) => state.isActive),
        take(1)
      )
      .subscribe((userIsActive) => {
        if (userIsActive) {
          this.headerLinks = [
            {
              label: 'Dashboard',
              href: this.appConfig.plansHomePage,
            },
            {
              label: 'Your details',
              href: this.appConfig.personalDetailsPage,
            },
            {
              label: 'Logout',
              routerPath: '/logout',
              icon: 'arrow-right-from-bracket',
            },
          ];
        } else {
          this.headerLinks = [];
        }
      });
  }
}
