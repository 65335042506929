<div class="grid-row info info--{{ theme }}">
  <div class="xs-12 flex">
    <svg class="svg-icon icon-{{ icon }}">
      <use [attr.xlink:href]="'#icon-' + icon"></use>
    </svg>
    <div class="flex-column info-body">
      <h5 class="typog-body-large-bold xs--block md--inline mr-3 mb-none">
        {{ title }}
      </h5>
      <div *ngIf="description" [innerHTML]="description"></div>
    </div>
    <span class="link typog-body-large-bold" (click)="changeCTA.emit()">{{
      cta
    }}</span>
  </div>
</div>
