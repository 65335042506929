import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromClaims from '../+state';
import * as claimActions from '../+state/claim.actions';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@domgen/dgx-components';
import { catchError, switchMap, tap, first } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Claim } from '@domgen/dgx-components';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private store: Store<fromClaims.State>,
    private http: HttpClient,
    private conf: ConfigService
  ) {}

  public correlationID?: string | null = null;

  private readonly defaultHomepageURL: string =
    'https://www.domesticandgeneral.com/';

  logout(
    routeToLogin: boolean = false,
    homepageURL?: string,
    callSignoutApi = true
  ) {
    return this.callSignout(callSignoutApi).pipe(
      tap(() => {
        routeToLogin
          ? this.redirectToLogin()
          : this.redirectToHomepage(homepageURL);

        this.clearClaim();
      }),
      catchError((err) => of(this.store.dispatch(claimActions.Error(err))))
    );
  }

  clearClaim() {
    this.store.dispatch(claimActions.ClearCurrentClaim());
  }

  redirectToHomepage(homepageURL?: string) {
    window.location.href = homepageURL ? homepageURL : this.defaultHomepageURL;
  }

  redirectToLogin() {
    window.location.href = this.conf.myAccountLogoutPage ?? '';
  }

  private callSignout(callSignoutApi: boolean) {
    return callSignoutApi
      ? this.http
          .post(`${this.conf.myAccountApi}/v1/signout`, {})
          .pipe(catchError(() => of(null)))
      : of(null);
  }
}
