import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { format } from 'date-fns';
import set from 'date-fns/set';
import { fadeInAnimation } from '../../../animations/animations';
import { Api, CalendarDay, CalendarSlot } from '../../../interfaces';
import {
  FormDataSchema,
  RadioGroupDefinition,
} from '../../../modules/formbuilder/interfaces/form-controls-definition.interface';
import { FormsService } from '../../../modules/formbuilder/services/forms.service';
import { SlotType } from '../booking-calendar-day/booking-calendar-day.component';

@Component({
  selector: 'dg-booking-calendar-day-specific-slot',
  templateUrl: './booking-calendar-day-specific-slot.component.html',
  styleUrls: ['./booking-calendar-day-specific-slot.component.scss'],
  animations: [fadeInAnimation],
})
export class BookingCalendarDaySpecificSlotComponent
  implements OnInit, OnChanges
{
  @Input() day?: CalendarDay;
  @Input() isRebook = false;
  @Input() bookingError = false;
  @Input() currentAppointment?: Api.CurrentAppointment;
  @Input() selectedItem: CalendarSlot | undefined;
  @Output() selected: EventEmitter<CalendarSlot> = new EventEmitter();
  @Output() temporarySelection: EventEmitter<CalendarSlot> =
    new EventEmitter<CalendarSlot>();

  formData?: FormDataSchema['meta'];
  form?: UntypedFormGroup;
  slotSelected: CalendarSlot | undefined = undefined;
  controlName = 'slotIdentifier';
  elementsPerPage = 4;

  showConfirmationModal = false;
  slotTypes = SlotType;

  constructor(private formsService: FormsService) {}

  ngOnInit(): void {
    this.formData = {
      groups: [
        {
          radios: {
            control: this.controlName,
            elementsPerPage: 4,
            classes: `d-flex radio--button`,
            validators: [],
            radioGroup: this.day?.slots?.map(
              (type: CalendarSlot, index: number) => ({
                value: type.identifier,
                text: `${this.formatTime(type.startTime)} - ${this.formatTime(
                  type.endTime
                )}`,
                classes: 'xs-12',
                hiddenAccessibilityText: '',
              })
            ) as RadioGroupDefinition[],
          },
        },
      ],
    };

    this.form = new UntypedFormGroup(
      this.formsService.defineformControls(this.formData.groups, {
        slotIdentifier: this.selectedItem?.identifier as string,
      })
    );

    if (this.selectedItem) {
      this.form
        .get('groups')
        ?.get('radios')
        ?.setValue(this.selectedItem.identifier);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookingError) {
      if (this.bookingError) {
        this.showConfirmationModal = false;
      }
    }
  }

  private formatTime(time: string): string {
    const amPm = time >= '12:00' ? 'pm' : 'am';
    return `${format(this.getDate(time), 'h:mm').replace(/:00/, '')}${amPm}`;
  }

  private getDate(time: string) {
    const [hours, minutes] = time.split(':');
    return set(this.day?.date as Date, {
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: 0,
    });
  }

  selectSlot() {
    const slot = this.day?.slots?.find(
      (s, index) => s.identifier === this.form?.value?.slotIdentifier
    ) as CalendarSlot;

    this.slotSelected = {
      ...slot,
      startTime: this.formatTime(slot.startTime),
      endTime: this.formatTime(slot.endTime),
    };

    this.temporarySelection.emit(this.slotSelected);
  }

  handleBookingConfirmation(confirm: boolean) {
    this.showConfirmationModal = confirm;
    if (confirm) {
      this.selected.emit(this.slotSelected);
    }
  }

  clearSlot() {
    this.form?.controls[this.controlName].patchValue(null);
  }

  seeOtherDates() {
    if (!this.day) return;

    this.day.selected = false;
  }
}
