import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DgxComponentsButtonModule } from '@domgen/dgx-fe-components/button';
import { SharedUiSvgModule } from '@domgen/ui-svg';
// import { TranslateModule } from '@ngx-translate/core';

import { DgxComponentsIconModule } from '@domgen/dgx-fe-components/icon';
import {
  DgxComponentsModalModule,
  ModalService,
} from '@domgen/dgx-fe-components/modal';
import { DgxComponentsTooltipModule } from '@domgen/dgx-fe-components/tooltip';
import { DgxQmModule } from '@domgen/dgx-fe-qm';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BookingCalendarConfirmationComponent } from './components/booking-calendar/booking-calendar-confirmation/booking-calendar-confirmation.component';
import { BookingCalendarDayAllDaySlotComponent } from './components/booking-calendar/booking-calendar-day-all-day-slot/booking-calendar-day-all-day-slot.component';
import { BookingCalendarDaySpecificSlotComponent } from './components/booking-calendar/booking-calendar-day-specific-slot/booking-calendar-day-specific-slot.component';
import { BookingCalendarDayComponent } from './components/booking-calendar/booking-calendar-day/booking-calendar-day.component';
import { BookingCalendarHeaderComponent } from './components/booking-calendar/booking-calendar-header/booking-calendar-header.component';
import { BookingCalendarNavigationComponent } from './components/booking-calendar/booking-calendar-navigation/booking-calendar-navigation.component';
import { BookingCalendarComponent } from './components/booking-calendar/booking-calendar.component';
import { BookingCurrentAppointmentComponent } from './components/booking-calendar/booking-current-appointment/booking-current-appointment';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarHelperService } from './components/calendar/services/calendar-helper.service';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { FlipCardComponent } from './components/flip-card/flip-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import { InformationNoticeComponent } from './components/information-notice/information-notice.component';
import { LegacyFlipCardComponent } from './components/legacy-flip-card/legacy-flip-card.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalDialogAlertComponent } from './components/modal-dialog-alert/modal-dialog-alert.component';
import { ModalDialogRetryComponent } from './components/modal-dialog-retry/modal-dialog-retry.component';
import { MyAccountBannerComponent } from './components/my-account-banner/my-account-banner.component';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { OpeningTimesComponent } from './components/opening-times/opening-times.component';
import { PrintComponent } from './components/print/print.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { ThreeColumnComponent } from './components/three-column-banner/three-column-banner.component';
import { AllDaySlotComponent } from './components/time-picker/all-day-slot/all-day-slot.component';
import { SpecificSlotComponent } from './components/time-picker/specific-slot/specific-slot.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { FormBuilderModule } from './modules/formbuilder/form-builder.module';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml';
import { TimeFormatPipe } from './pipes/timeFormat';
import { PcaService } from './services/pca.service';
import { A11yModule } from '@angular/cdk/a11y';
@NgModule({
  declarations: [
    CalendarComponent,
    FlipCardComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    TimePickerComponent,
    SpecificSlotComponent,
    PrintComponent,
    AllDaySlotComponent,
    SpecificSlotComponent,
    SanitizeHtmlPipe,
    TimeFormatPipe,
    SubHeaderComponent,
    InfoMessageComponent,
    ContactDetailsComponent,
    ModalDialogAlertComponent,
    OpeningTimesComponent,
    LegacyFlipCardComponent,
    AccordionComponent,
    BookingCalendarComponent,
    BookingCalendarConfirmationComponent,
    BookingCalendarHeaderComponent,
    BookingCalendarNavigationComponent,
    ThreeColumnComponent,
    MyAccountBannerComponent,
    BookingCalendarDayComponent,
    BookingCalendarDayAllDaySlotComponent,
    BookingCalendarDaySpecificSlotComponent,
    ModalDialogRetryComponent,
    NotificationBannerComponent,
    InformationNoticeComponent,
    BookingCurrentAppointmentComponent,
  ],
  exports: [
    CalendarComponent,
    FlipCardComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    TimePickerComponent,
    SpecificSlotComponent,
    PrintComponent,
    AllDaySlotComponent,
    SpecificSlotComponent,
    SanitizeHtmlPipe,
    TimeFormatPipe,
    // TranslateModule,
    SubHeaderComponent,
    InfoMessageComponent,
    ContactDetailsComponent,
    ModalDialogAlertComponent,
    OpeningTimesComponent,
    LegacyFlipCardComponent,
    AccordionComponent,
    BookingCalendarComponent,
    BookingCalendarConfirmationComponent,
    BookingCalendarHeaderComponent,
    BookingCalendarNavigationComponent,
    ThreeColumnComponent,
    MyAccountBannerComponent,
    BookingCalendarDayAllDaySlotComponent,
    BookingCalendarDaySpecificSlotComponent,
    ModalDialogRetryComponent,
    InformationNoticeComponent,
    NotificationBannerComponent,
    BookingCurrentAppointmentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    // TranslateModule,
    FormBuilderModule,
    DgxComponentsButtonModule,
    SharedUiSvgModule,
    DgxQmModule,
    DgxComponentsIconModule,
    DgxComponentsModalModule,
    DgxComponentsTooltipModule,
    A11yModule,
  ],
  providers: [CalendarHelperService, PcaService, ModalService],
})
export class SharedComponentsUiModule {}
