import { find, findIndex, reduce } from 'lodash-es';

import {
  Api,
  Claim,
  ClaimBundleType,
  ClaimQuestion,
  ClaimSelection,
  ClaimStage,
  Payment,
  PaymentType,
  PreClaimStartPageViewed,
  ServiceOptionTypes,
  UserStateTypeEnum,
} from '@domgen/dgx-components';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfWeek,
  subDays,
} from 'date-fns';
import { claimHelper } from '../helper/claim-state-helper';
import * as ClaimActionTypes from './claim.actions';
import { RebookDataAlreadyLoaded } from './claim.actions';

export const ClaimFeatureKey = 'claim';

// State for this feature (Claim)
export interface State extends EntityState<Claim> {
  isLoading: boolean;
  loadingMessage: string | null;
  manufacturers: string[] | undefined;
  productTypes: string[] | undefined;
  applianceSuperCategory: string | null;
  planNumber: string | null;
}

export const adapter: EntityAdapter<Claim> = createEntityAdapter<Claim>({
  selectId: (c: Claim) => c?.reflect?.planNumber as string,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  loadingMessage: null,
  manufacturers: undefined,
  productTypes: undefined,
  applianceSuperCategory: null,
  planNumber: null,
});

export const reducer = createReducer(
  initialState,
  on(ClaimActionTypes.InitializeCurrentClaim, (state) => {
    return { ...state, isLoading: true };
  }),
  on(ClaimActionTypes.GetReflectDataSuccess, (state, { payload }) => {
    const foundClaim = find(state.entities, (c) => {
      return (
        (c?.reflect?.planNumber as string) ===
        (payload.reflect?.planNumber as string)
      );
    });

    const isSameBundleToken = !payload.newHandoff;

    const reflect =
      foundClaim && isSameBundleToken
        ? // overwrite reflect.manufacturer and claimType
          // if in Local Storage
          {
            ...payload.reflect,
            manufacturer: foundClaim.reflect?.manufacturer,
            claimType: foundClaim.reflect?.claimType,
            journeyState: foundClaim.reflect?.journeyState,
          }
        : payload.reflect;

    return {
      ...adapter[isSameBundleToken ? 'upsertOne' : 'setOne'](
        {
          bundleToken: isSameBundleToken
            ? foundClaim?.bundleToken
            : payload?.bundleToken,
          reflect: {
            ...reflect,
            userState:
              foundClaim && isSameBundleToken
                ? foundClaim?.reflect?.userState
                : payload.reflect.userState,
          },
          claimedClientName: payload.reflect.manufacturer,
          excess: foundClaim?.excess
            ? {
                ...foundClaim?.excess,
              }
            : undefined,
        },
        state
      ),
      planNumber: payload?.reflect?.planNumber as string,
    };
  }),
  on(ClaimActionTypes.LoadClaimDataSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        ...payload,
        bookingApiTimeout: false,
        slotUnavailable: false,
        bookingOption: null,
        bookingAvailable: false,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClaimAccepted, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        accepted: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.AutoCreateClaim, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        getData: payload.getData,
        reflect: payload?.isASV
          ? {
              ...claim?.reflect,
              claimType: ClaimBundleType.ANNUAL_SERVICE,
            }
          : claim?.reflect,
      },
      state
    );
  }),

  on(ClaimActionTypes.ClaimRejected, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        rejected: true,
        rejectedMessage: payload.rejectedMessage,
      },
      state
    );
  }),
  on(ClaimActionTypes.UpdateManufacturer, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        reflect: {
          ...claim?.reflect,
          manufacturer: payload,
        },
      },
      state
    );
  }),
  on(
    ClaimActionTypes.UpdateStartAvailabilityDate,
    (state, { newStartDate }) => {
      const claim = claimHelper.getClaim(state);

      return adapter.upsertOne(
        {
          ...claim,
          bookingOption: {
            ...(claim.bookingOption as Api.PutServiceOptionResponse),
            AvailabilityStartDate: newStartDate,
          },
        },
        state
      );
    }
  ),
  on(ClaimActionTypes.UpdateClaimSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        getData: payload.getData,
        claimSelection: {
          selectionState: {
            ...claim?.claimSelection?.selectionState,
            ...payload?.update?.selectionState,
          },
          request: {
            ...claim?.claimSelection?.request,
            ...payload.update.request,
          },
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.GetQuestion, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        claimSelection: <ClaimSelection>{
          request: {
            ...claim?.claimSelection?.request,
            ...payload.update.request,
          },
          selectionState: {
            ...claim?.claimSelection?.selectionState,
            ...payload.update.selectionState,
          },
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.CreateClaim, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        claimSelection: {
          selectionState: {
            ...claim?.claimSelection?.selectionState,
            ...payload?.selectionState,
          },
          request: {
            ...claim?.claimSelection?.request,
            ...payload.request,
          },
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.CreateCallbackClaim, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        getData: payload,
        accepted: false,
        created: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.ContactDetailsCallbackClaim, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        accepted: true,
        callbackQA: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.CompleteCallbackBookingSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        accepted: true,
        complete: true,
        claimID: payload.reference,
        callbackConfirmation: {
          ...claim.callbackConfirmation,
          reference: payload.reference,
        },
        claimConfirmed: payload.claimConfirmation,
        bookedServiceOption: ServiceOptionTypes.Callback,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetCallbackWidget, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        customer: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetCallbackWidgetSuccess, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        callbackWidgetEnabled: true,
        accepted: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.CreateClaimSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        accepted: false,
        created: true,
        bookingAvailable: false,
        claimID: payload.response.ClaimID,
        questions: [
          {
            question: payload.response.question,
            answer: null,
          },
        ],
      },
      state
    );
  }),
  on(ClaimActionTypes.PutAnswer, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    const questions = reduce(
      claim?.questions,
      (init: ClaimQuestion[], current: ClaimQuestion) => {
        current = { ...current };
        if (
          Number(current.question.QuestionID) === Number(payload.QuestionID)
        ) {
          current.answer = {
            AnswerID: payload.AnswerID,
            AnswerValue: payload.AnswerValue,
          };
        }

        init.push(current);
        return init;
      },
      []
    );

    return adapter.upsertOne(
      {
        ...claim,
        questions: [...questions],
      },
      state
    );
  }),
  on(ClaimActionTypes.GetQuestionSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    // find index of current question being answered
    const result = findIndex(claim?.questions, (q) => {
      return (
        Number(q.question.QuestionID) === Number(payload.request.QuestionID)
      );
    });

    // filter out all questions after the current one being answered
    const questions = claim?.questions
      ?.filter((q, index) => {
        return index <= result;
      })
      .map((v) => {
        return (v = { ...v });
      }) as ClaimQuestion[];

    questions.push({
      question: payload.response,
      answer: null,
    });

    return adapter.upsertOne(
      {
        ...claim,
        questions: [...questions],
      },
      state
    );
  }),
  on(ClaimActionTypes.LoadServiceOptions, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    const payment = payload?.PaymentData?.find(
      (a: Api.PaymentData) =>
        a.Type === PaymentType.EXCESS || a.Type === PaymentType.CALLOUT
    );
    return adapter.upsertOne(
      {
        ...claim,
        serviceOptions: payload,
        excess: {
          ...claim?.excess,
          required: payload?.PaymentRequired ? true : false,
          cost: payment?.Amount || '',
          type: payment?.Type as PaymentType,
        },
      },
      state
    );
  }),
  on(
    ClaimActionTypes.UpdateUserAddressDetailsSuccess,
    ClaimActionTypes.UpdateUserAddressDetailsFailed,
    (state, { payload }) => {
      const claim = claimHelper.getClaim(state);
      return adapter.upsertOne(
        {
          ...claim,
          userAddressDetailsUpdated: payload,
        },
        state
      );
    }
  ),
  on(
    ClaimActionTypes.UpdateUserPersonalDetailsSuccess,
    ClaimActionTypes.UpdateUserPersonalDetailsFailed,
    (state, { payload }) => {
      const claim = claimHelper.getClaim(state);
      return adapter.upsertOne(
        {
          ...claim,
          userPersonalDetailsUpdated: payload,
        },
        state
      );
    }
  ),
  on(ClaimActionTypes.SelectServiceOption, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        serviceOption: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetServiceOptions, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        customer: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.ResetBooking, (state) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        bookingApiTimeout: false,
        slotUnavailable: false,
        bookingOption: null,
        bookingAvailable: false,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClaimRebook, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        getData: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.PutServiceOptionSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    const { formattedSlots, AvailabilityEndDate, AvailabilityStartDate } =
      UpdateSlotsByWeekData(
        state,
        payload.response.AvailabilityStartDate as string,
        payload.response.AvailabilityData as Api.BookingDate[],
        false,
        true,
        claim?.bookingOption?.AllReturnedDates || []
      );

    const extraAvailability = isBefore(
      new Date(formattedSlots[formattedSlots.length - 1].WeekEnd),
      addDays(new Date(), payload.response.AvailabilityMaximumDays as number)
    );

    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...payload.response,
          AvailabilityData: [
            ...(claim?.bookingOption?.AvailabilityData || []),
            ...(payload?.response?.AvailabilityData || []),
          ],
          SlotsByWeek: formattedSlots,
          AvailabilityEndDate,
          AvailabilityStartDate,
          ExtraAvailability: extraAvailability,
          AllReturnedDates: [
            ...(claim?.bookingOption?.AllReturnedDates || []),
            ...(payload?.response?.AvailabilityData || []),
          ],
        },
        bookingAvailable: true,
        isServiceProviderCallback: payload?.isServiceProviderCallback,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetBookingFollowingWeekSlots, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...payload,
          AvailabilityData: [
            ...(claim?.bookingOption?.AvailabilityData || []),
            ...(payload?.AvailabilityData || []),
          ],
          SlotsByWeek: claim?.bookingOption?.SlotsByWeek || [],
        },
        bookingAvailable: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.DropOffPutServiceOptionSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...payload.response,
        },
        dropOffOrigin: payload.dropOffOrigin,
        bookingAvailable: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.CreateNewAppointmentSlot, (state, { dateStart }) => {
    const claim = claimHelper.getClaim(state);
    const { formattedSlots, AvailabilityEndDate, AvailabilityStartDate } =
      UpdateSlotsByWeekData(
        state,
        dateStart.toString(),
        [],
        true,
        true,
        claim?.bookingOption?.AllReturnedDates || []
      );

    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...(claim.bookingOption as Api.PutServiceOptionResponse),
          SlotsByWeek: formattedSlots,
          AvailabilityEndDate,
          AvailabilityStartDate,
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.GetMoreAppointmentsSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    const { formattedSlots, AvailabilityEndDate, AvailabilityStartDate } =
      UpdateSlotsByWeekData(
        state,
        payload.AvailabilityStartDate as string,
        payload.AvailabilityData as Api.BookingDate[],
        false,
        true,
        claim?.bookingOption?.AllReturnedDates || []
      );

    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...(claim.bookingOption as Api.PutServiceOptionResponse),
          ...payload,
          SlotsByWeek: formattedSlots,
          AvailabilityEndDate,
          AvailabilityStartDate,
          AllReturnedDates: [
            ...(claim?.bookingOption?.AllReturnedDates || []),
            ...(payload.AvailabilityData as Api.BookingDate[]),
          ],
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.RebookClaimDataSuccess, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);

    const { formattedSlots, AvailabilityEndDate, AvailabilityStartDate } =
      UpdateSlotsByWeekData(
        state,
        payload.AvailabilityStartDate as string,
        payload.AvailabilityData as Api.BookingDate[],
        false,
        false,
        claim?.bookingOption?.AllReturnedDates || []
      );

    const extraAvailability = isBefore(
      new Date(formattedSlots[formattedSlots.length - 1].WeekEnd),
      addDays(new Date(), payload.AvailabilityMaximumDays as number)
    );

    return adapter.upsertOne(
      {
        ...claim,
        bookingOption: {
          ...payload,
          SlotsByWeek: formattedSlots,
          ExtraAvailability: extraAvailability,
          AvailabilityEndDate,
          AvailabilityStartDate,
          AllReturnedDates: [
            ...(claim?.bookingOption?.AllReturnedDates || []),
            ...(payload.AvailabilityData as Api.BookingDate[]),
          ],
        },
        bookingAvailable: true,
        bookedServiceOption: ServiceOptionTypes.Home,
        isRebook: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.RebookDataAlreadyLoaded, (state) => {
    const claim = claimHelper.getClaim(state);

    return adapter.upsertOne(
      {
        ...claim,
        bookingAvailable: true,
        bookedServiceOption: ServiceOptionTypes.Home,
        isRebook: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.BookingSlotUnavailable, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookingSelection: null,
        slotUnavailable: true,
        bookingOption: {
          ...claim?.bookingOption,
          AvailabilityData: claim?.bookingOption?.AvailabilityData?.filter(
            (day: Api.BookingDate) =>
              day?.Date === claim?.bookingSelection?.date
          ),
        } as Api.PutServiceOptionResponse,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetBookingExtraSlotsFailed, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        bookingApiTimeout: true,
        bookingOption: {
          ...claim?.bookingOption,
          ...payload,
        } as Api.PutServiceOptionResponse,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClearBookingErrors, (state) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        bookingApiTimeout: false,
        slotUnavailable: false,
      },
      state
    );
  }),
  on(ClaimActionTypes.ManualBooking, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        noBookingDatesFound: payload.noBookingDatesFound,
        bookedServiceOption: ServiceOptionTypes.Manual,
      },
      state
    );
  }),
  on(ClaimActionTypes.CompleteCallbackBooking, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        callbackConfirmation: {
          callbackTime: payload?.desiredTime,
        },
        bookedServiceOption: ServiceOptionTypes.Callback,
      },
      state
    );
  }),
  on(ClaimActionTypes.CollectionBooking, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.Collection,
      },
      state
    );
  }),
  on(ClaimActionTypes.PayAndClaim, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.PayClaim,
      },
      state
    );
  }),
  on(ClaimActionTypes.SelfSendBooking, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.SelfSend,
      },
      state
    );
  }),
  on(ClaimActionTypes.EngineerBooking, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        bookedServiceOption: ServiceOptionTypes.Home,
      },
      state
    );
  }),
  on(ClaimActionTypes.GetClaimQA, (state, { payload }) => {
    return {
      ...adapter.upsertOne(
        {
          ...claimHelper.getClaim(state),
          isGasAppliance: payload.superCat.isGasAppliance,
          getData: payload.getData,
        },
        state
      ),
      applianceSuperCategory: payload.superCat.applianceSuperCategory,
    };
  }),
  on(ClaimActionTypes.DropOffBooking, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        dropOffOrigin: payload.dropOffOrigin,
        bookedServiceOption: ServiceOptionTypes.DropOff,
      },
      state
    );
  }),
  on(ClaimActionTypes.PutRepairData, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        bookingSelection: payload.bookingSelection,
        customer: payload.customerDetails,
      },
      state
    );
  }),

  on(ClaimActionTypes.PutRepairDataSuccess, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        claimID: payload.response?.ClaimID,
        claimReference: payload.response?.ClaimReference,
        claimConfirmed: payload.response,
        errorResponse: payload.errorResponse,
        complete: true,
      },
      state
    );
  }),
  on(ClaimActionTypes.PutNewAppointment, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        bookingSelection: payload.bookingSelection,
      },
      state
    );
  }),
  on(ClaimActionTypes.ClearCurrentClaim, (state) => {
    const claim = claimHelper.getClaim(state);
    return {
      ...adapter.removeOne(claim?.reflect?.planNumber as string, state),
      manufacturers: undefined,
      productTypes: undefined,
      applianceSuperCategory: null,
    };
  }),
  on(ClaimActionTypes.SetManufacturers, (state, { payload }) => {
    return {
      ...state,
      manufacturers: payload,
    };
  }),
  on(ClaimActionTypes.SetProductTypes, (state, { payload }) => {
    return {
      ...state,
      productTypes: payload,
    };
  }),
  on(ClaimActionTypes.GetSuperCategorySuccess, (state, { payload }) => {
    return {
      ...adapter.upsertOne(
        {
          ...claimHelper.getClaim(state),
          isGasAppliance: payload.isGasAppliance,
        },
        state
      ),
      applianceSuperCategory: payload.applianceSuperCategory,
    };
  }),
  on(ClaimActionTypes.SeenGasSafetyNotice, (state) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        preClaimStartPageViewed: PreClaimStartPageViewed.GasSafety,
      },
      state
    );
  }),
  on(ClaimActionTypes.SeenMaintenanceSignpost, (state) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        preClaimStartPageViewed: PreClaimStartPageViewed.MaintenanceSignpost,
      },
      state
    );
  }),
  on(ClaimActionTypes.SetOneProductType, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        claimSelection: {
          request: { ProductType: payload.productType },
          selectionState: { claimStage: ClaimStage.SetOneProductType },
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.LoadCardPaymentRedirect, (state, { payload }) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        serviceOption: payload.selectedServiceOption,
        excess: {
          ...claim.excess,
          request: payload.request,
        } as Payment,
      },
      state
    );
  }),
  on(ClaimActionTypes.LoadCardPaymentRedirectSuccess, (state, { payload }) => {
    return adapter.upsertOne(
      {
        ...claimHelper.getClaim(state),
        worldpay: payload,
      },
      state
    );
  }),
  on(ClaimActionTypes.CompletePaymentSuccess, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        excess: {
          ...claim?.excess,
          required: false,
          cost: claim.excess?.cost as string,
          type: claim.excess?.type as PaymentType,
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.SetPasswordSuccess, (state) => {
    const claim = claimHelper.getClaim(state);
    return adapter.upsertOne(
      {
        ...claim,
        reflect: {
          ...claim.reflect,
          userState: UserStateTypeEnum.COMPLETE,
        },
      },
      state
    );
  }),
  on(ClaimActionTypes.Loading, (state, { payload }) => {
    return {
      ...state,
      loadingMessage: payload as string,
      isLoading: true,
    };
  }),
  on(ClaimActionTypes.Loaded, (state) => {
    return {
      ...state,
      loadingMessage: null,
      isLoading: false,
    };
  })
);

export function UpdateSlotsByWeekData(
  state: State,
  startDate: string,
  availabilityData: Api.BookingDate[],
  loading: boolean = false,
  forceInsert: boolean = false,
  allLoadedDates: Api.BookingDate[] = []
): {
  formattedSlots: Api.SlotsByWeek[];
  AvailabilityEndDate: string;
  AvailabilityStartDate: string;
} {
  const claim = claimHelper.getClaim(state);
  const { SlotsByWeek } = claim?.bookingOption || {};
  const formattedSlots: Api.SlotsByWeek[] = SlotsByWeek
    ? JSON.parse(JSON.stringify(SlotsByWeek))
    : [];

  const generateFormattedSlots = (
    dates: Api.BookingDate[] = [],
    weekStartDate: string
  ) => {
    const formattedStartDate = startOfWeek(new Date(weekStartDate), {
      weekStartsOn: 1,
    });
    const formattedEndDate = addDays(formattedStartDate, 6);
    const startOfNextWeek = addDays(formattedStartDate, 7);

    const existingDatesWithinWeek = allLoadedDates
      .filter((date) => {
        return (
          isAfter(new Date(date.Date), subDays(formattedStartDate, 1)) &&
          isBefore(new Date(date.Date), formattedEndDate)
        );
      })
      .filter((i) => {
        const dateExistsInResponse = dates.find((d) => d.Date === i.Date);

        return dateExistsInResponse === undefined;
      });

    dates = [...existingDatesWithinWeek, ...dates];

    const datesWithinWeek = JSON.parse(JSON.stringify(dates)).filter(
      (i: Api.BookingDate) => isBefore(new Date(i.Date), startOfNextWeek)
    );
    const datesAfterWeek = JSON.parse(JSON.stringify(dates)).filter(
      (i: Api.BookingDate) => isAfter(new Date(i.Date), formattedEndDate)
    );

    if (datesWithinWeek.length || forceInsert) {
      const existingDateIndex = formattedSlots.findIndex(
        (slot: Api.SlotsByWeek) =>
          isSameDay(formattedStartDate, new Date(slot.WeekStart)) &&
          isSameDay(formattedEndDate, new Date(slot.WeekEnd))
      );

      if (existingDateIndex === -1) {
        formattedSlots.push({
          WeekStart: formattedStartDate.toISOString(),
          WeekEnd: formattedEndDate.toISOString(),
          AvailabilityData: datesWithinWeek || [],
          Loading: loading,
        });
      } else {
        formattedSlots[existingDateIndex].Loading = loading;
        formattedSlots[existingDateIndex].AvailabilityData = datesWithinWeek;
      }
    }

    if (datesAfterWeek.length) {
      const datesExtendOverAWeek = datesAfterWeek.find((i: Api.BookingDate) => {
        return isAfter(new Date(i.Date), addDays(startOfNextWeek, 7));
      });

      if (datesExtendOverAWeek) {
        generateFormattedSlots(datesAfterWeek, startOfNextWeek.toString());
      }
    }
  };

  generateFormattedSlots(availabilityData, startDate);

  const { WeekStart, WeekEnd } = JSON.parse(
    JSON.stringify(formattedSlots[formattedSlots.length - 1] || {})
  );

  return {
    formattedSlots,
    AvailabilityEndDate: format(new Date(WeekEnd), 'yyyy-MM-dd'),
    AvailabilityStartDate: format(new Date(WeekStart), 'yyyy-MM-dd'),
  };
}
